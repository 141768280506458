<template>
  <el-main>
    <page-title />
    <div class="partition-area">
      <ics-search-inner :search-form="searchForm" more-btn="高级搜索" :fold-search.sync="showFoldSearch" @submit-search="search" @clear-search="clearSearch">
        <template>
          <el-col :span="6">
            <el-form-item label="融资企业编号">
              <el-input v-model="searchForm.customerCode" placeholder="请输入要搜索的融资企业编号" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="融资企业名称">
              <el-input v-model="searchForm.customerName" placeholder="请输入要搜索的融资企业名称" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="统一社会信用代码">
              <el-input v-model="searchForm.customerSocialNo" placeholder="请输入要搜索的统一社会信用代码" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="状态">
              <el-select v-model="searchForm.enabled" placeholder="请选择要搜索的状态" filterable>
                <el-option label="全部" value="" />
                <el-option v-for="item in constants.enableStatus" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col v-show="showFoldSearch" :span="6">
            <el-form-item label="创建人">
              <el-input v-model="searchForm.createdBy" placeholder="请输入要搜索的创建人" />
            </el-form-item>
          </el-col>
          <el-col v-show="showFoldSearch" :span="12">
            <el-form-item label="创建时间">
              <ics-search-date :date.sync="createdArr" />
            </el-form-item>
          </el-col>
        </template>
      </ics-search-inner>
    </div>
    <div class="partition-table">
      <ics-table-inner v-loading="loading.list" :column-option="columnOption" :table-data="tableList.list" :page-num="tableList.pageNum" :page-size="tableList.pageSize" :pages="tableList.pages" :hide-export-btn="true" @changePageNum="changePageNum">
        <template slot="btn-inner">
          <el-button v-loading="loading.export" type="primary" @click="exportFile">
            导出
          </el-button>
        </template>
        <template slot="table-columns-before">
          <el-table-column fixed="left" label="序号" type="index" :index="indexMethod" />
        </template>
        <template slot="table-columns-after">
          <el-table-column fixed="right" label="操作" width="80">
            <template v-slot="scope">
              <el-link type="primary" class="text-btn" @click="$router.push({ name: 'customerDetail', query: { customerCode: scope.row.customerCode } })">
                详情
              </el-link>
            </template>
          </el-table-column>
        </template>
      </ics-table-inner>
    </div>
  </el-main>
</template>

<script>
import { basePageListMixin } from '@/assets/js/mixins'
import utils from '@/assets/js/utils'
export default {
  components: {},
  mixins: [basePageListMixin],
  data () {
    return {
      showFoldSearch: false,
      baseSearchForm: {
        customerCode: '',
        customerName: '',
        customerSocialNo: '',
        enabled: '',
        createdBy: '',
        createdStart: '',
        createdEnd: ''
      },
      columnOption: [
        { label: '融资企业编号', prop: 'customerCode', formatter: this.utils.isEffective, canSet: false, fixed: 'left', minWidth: 100 },
        { label: '融资企业名称', prop: 'customerName', formatter: this.utils.isEffective, minWidth: 140 },
        { label: '统一社会信用代码', prop: 'customerSocialNo', formatter: this.utils.isEffective, minWidth: 140 },
        { label: '创建时间', prop: 'createdAt', formatter: this.utils.isEffective, minWidth: 140 },
        { label: '创建人', prop: 'createdBy', formatter: this.utils.isEffective, minWidth: 140 },
        { label: '状态', prop: 'enabled', formatter: (row, col, cell) => this.utils.statusFormat(Number(cell), 'enableStatus'), minWidth: 100 },
      ]
    }
  },
  computed: {
    createdArr: utils.computedDate('createdStart', 'createdEnd')
  },
  methods: {
    getList () {
      this.loading.list = true
      this.api.company.customer.pageList(this.utils.filterTrimValue(this.searchForm)).then(result => {
        this.tableList = result.data.data
      }).finally(() => {
        this.loading.list = false
      })
    },
    exportFile () {
      this.loading.export = true
      this.api.company.customer.exportFile().then(result => this.utils.exportFile(result)).finally(() => {
        this.loading.export = false
      })
    },
    enableCustomer  (customerCode, enable) {
      this.api.company.customer.enableCustomer(customerCode, enable).then(result => {
        this.$message.success(result.data.message || '操作成功')
        this.getList()
      }).finally(() => {
      })
    }
  }
}
</script>

<style>

</style>
